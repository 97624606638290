import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Layout from "../components/layout"
import Slideshow from "../components/slideshow"
import slideshow_icon from "../assets/icons/slideshow.svg"

export default function Exhibition({ location, data }) {
  let page_heading = data.exhibition.frontmatter.title
  if (data.exhibition.headings.length > 0) {
    if (data.exhibition.headings[0].depth === 1) {
      page_heading = data.exhibition.headings[0].value
    }
  }

  const exhibition_images = data.exhibition.file.images
                                .map(i => i.thumb)
                                .filter(i => i !== undefined)
                                .sort((a, b) => { return a.fixed.originalName > b.fixed.originalName})
  const slideshow_images = data.exhibition.file.images
                               .map(i => i.slide)
                               .filter(i => i !== undefined)
                               .sort((a, b) => { return a.fixed.originalName > b.fixed.originalName})
  const words_per_thumb = 22
  const text_grid_columns = Math.round(
    (data.exhibition.wordCount.words /
      (data.exhibition.wordCount.words + exhibition_images.length * words_per_thumb)
    ) * 12
  )
  let text_grid_width;
  if (text_grid_columns === 0) {
    text_grid_width = "w-0";
  } else if (text_grid_columns === 12) {
    text_grid_width = "w-full";
  } else if (text_grid_columns < 3) {
    text_grid_width = `ww3-12`;
  } else if (text_grid_columns > 9) {
    text_grid_width = `ww9-12`;
  } else {
    text_grid_width = `ww${text_grid_columns}-12`;
  }
  const image_grid_columns = Math.round(
    (exhibition_images.length * words_per_thumb /
      (data.exhibition.wordCount.words + exhibition_images.length * words_per_thumb)
    ) * 12
  )
  let image_grid_width;
  if (image_grid_columns === 0) {
    image_grid_width = "w-0";
  } else if (image_grid_columns === 12) {
    image_grid_width = "w-full";
  } else if (image_grid_columns < 3) {
    image_grid_width = `ww3-12`;
  } else if (image_grid_columns > 9) {
    image_grid_width = `ww9-12`;
  } else {
    image_grid_width = `ww${image_grid_columns}-12`;
  }
  return (
    <Layout location={location} title={page_heading}>
      <header className="page--header">
        <h1>{page_heading}</h1>
        <p>opened on {data.exhibition.frontmatter.date} at {data.exhibition.frontmatter.gallery}</p>
      </header>
      <p><a href="#exhibition-slideshow"><img src={slideshow_icon} alt="Exhibition slideshow" /></a></p>
      <div className="exhibition--grid">
        <div className={`exhibition--text ${text_grid_width}`}>
          <div dangerouslySetInnerHTML={{ __html: data.exhibition.html }} />
          <ul className="pages-linking-here">
            {data.linked_pages.edges.map((node) => {
              return (
                <li key={node.node.id}>
                  <Link to={node.node.fields.slug}>{node.node.frontmatter.title}</Link>
                </li>
              )
            })}
          </ul>
        </div>
        <ul className={`exhibition--images ${image_grid_width}`}>
          {exhibition_images.map((node) => {
            if (node) {
              return (
                <li key={node.id}>
                  <figure>
                    <Img fixed={node.fixed} alt={node.fields.caption} />
                    <figcaption>{node.fields.caption}</figcaption>
                  </figure>
                </li>
              )
            } else {
              return ""
            }
          })}
        </ul>
      </div>
      <div id="exhibition-slideshow" className="responsive-slideshow">
        <Slideshow images={slideshow_images} transition={1} />
      </div>
    </Layout>
  )
}

export const query = graphql`
query Exhibition ($slug: String!) {
  exhibition: markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    frontmatter {
      date(formatString: "Mo MMMM YYYY")
      gallery
      title
    }
    headings {
      id
      value
      depth
    }
    wordCount {
      words
    }
    file: parent {
      images: children {
        id
        ... on ImageSharp {
          id
          fixed {
            height
            width
            src
            originalName
          }
        }
        ... on File {
          id
          name
          slide: childImageSharp {
            id
            fixed(fit: INSIDE, height: 1600, width: 1600) {
              base64
              originalName
              srcSet
              aspectRatio
              width
              height
            }
            fluid(fit: INSIDE, maxHeight: 1600, maxWidth: 1600, srcSetBreakpoints: [320, 640, 960, 1280, 1600]) {
              base64
              originalName
              srcSet
              aspectRatio
              src
            }
            fields {
              caption
            }
          }
          thumb: childImageSharp {
            id
            fixed(fit: INSIDE, height: 144, width: 144) {
              base64
              height
              originalName
              srcSet
              width
            }
            fields {
              caption
            }
          }
          publicURL
          extension
        }
      }
    }
  }
  linked_pages: allMarkdownRemark(filter: {frontmatter: {exhibition: {eq: $slug}}}) {
    edges {
      node {
        frontmatter {
          title
          author
          publication_date(formatString: "Mo MMMM YYYY")
          publication_type
          exhibition
        }
        fields {
          slug
        }
        id
      }
    }
  }
}
`
